export default function cleanupStoryblokUrls(content: any) {
  if (!content || typeof content !== 'object') {
    return content
  }

  const removeTrailingSlash = (url: string) => {
    // Remove trailing slashes but keep at least one slash if it's the root URL
    return url.length > 1 ? url.replace(/\/+$/, '') : url
  }

  const processNode = (node: any) => {
    if (typeof node === 'object' && node !== null) {
      if (node.type === 'link' && node.attrs && node.attrs.href) {
        let href = node.attrs.href

        // Return if node.attrs.linktype is not story
        if (node.attrs.linktype !== 'story') {
          return node
        }

        if (!href.startsWith('/en/')) {
          // Check if the href has a leading slash
          if (href.startsWith('/')) {
            // Add a leading slash if it's missing
            href = `/en${href}`
          }
          else {
            href = `/en/${href}`
          }
        }

        // Remove trailing slashes
        href = removeTrailingSlash(href)

        // Reattach fragment if present
        const [urlPart, fragment] = href.split('#')
        if (fragment) {
          href = `${removeTrailingSlash(urlPart)}#${fragment}`
        }
        else {
          href = removeTrailingSlash(href)
        }

        node.attrs.href = href
      }

      Object.keys(node).forEach((key) => {
        if (Array.isArray(node[key])) {
          node[key] = node[key].map(processNode)
        }
        else if (typeof node[key] === 'object') {
          processNode(node[key])
        }
      })
    }
    return node
  }

  return processNode(JSON.parse(JSON.stringify(content)))
}
