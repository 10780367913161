import { renderRichText } from '@storyblok/vue'
import cleanupStoryblokUrls from './cleanupStoryblokUrls'

// Custom function to clean up and render rich text
export function customRenderRichText(richtext) {
  // Check if richtext is a valid object
  if (!richtext || typeof richtext !== 'object') {
    return ''
  }

  // Call the cleanup function on the rich text content
  const cleanedRichText = cleanupStoryblokUrls(richtext)

  // Render the cleaned rich text content to HTML
  const renderedHtml = renderRichText(cleanedRichText)

  return renderedHtml
}
